@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);
/* @import 'https://fonts.googleapis.com/css?family=Open+Sans:300, 400'; */

body {
  margin: 0;
  padding: 0;
  /* font-family: 'Open Sans', sans-serif; */
  font-family: 'Roboto', sans-serif;
  background: rgba(0, 0, 0, 0.05);
}

html {
  box-sizing: border-box;
}

input {
  height: 40px!important;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.white {
  color: white;
}
.post {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
  width: 350px;
  height: 350px;
  flex: 0 0 350px;
}
.tac {
  text-align: center;
}

.width50Perc {
  width: 50%;
}
.width100Perc {
  width: 100%;
}
.cursor {
  cursor: pointer;
}

.post .description {
  flex: 1 1;
  padding-left: 25px;
  font-size: 25px;
}

.detail {
  width: 600px;
  height: 600px;
  flex: 0 0 600px;
}

.detail .description {
  flex: 1 1;
  padding-left: 40px;
  font-size: 38px;
}

.title {
  font-size: 42px;
}

.content {
  font-size: 18px;
}

.new-post {
  background-color: rgba(0, 0, 0, 0.04);
}

.close {
  opacity: 0.5;
  margin-top: 60px;
  margin-right: 60px;
}

.close img {
  width: 20px;
  height: 20px;
}

.crop{
  height: 150px;
  width: 200px;
  overflow: hidden;
}

.crop img{
  width: 100%;
  height: auto;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%); /* Ch <36, Saf 5.1+, iOS < 9.2, An =<4.4.4 */ /* IE 9 */
  transform: translateY(-50%); /* IE 10, Fx 16+, Op 12.1+ */
}

.menuButton {
  margin-left: -12;
  margin-right: 20;
}

/* header */
.flexGrow {
  flex-grow:1;
}
.flex {
  flex:1 1;
}
/* header */

.paperIn {
  padding: 20px;
}
.paperOut {
  margin: 10px;
}

@media only screen and (min-width: 1025px) {
  .desktopMargin {
    margin-left: 256px
  }
}

/* signup */
.wrapperAnimate{
  -webkit-transition: width .25s linear;
  transition: width .25s linear;
}
.focusField {
  width: 70%;
}
.notFocusField {
  width: 40%;
  opacity: 0.5;
}
/* signup */

